import { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns';

import useOnScreen from 'utils/hooks/use-onscreen';

import PostContent from 'components/content-blocks/post-content';
import Yoast from 'components/globals/yoast';
import FontIcon from 'components/icons';
import Link from 'components/link/link';
import Signups from 'components/modal/signups';
import ScrollToTop from 'components/scroll-to-top/scroll-to-top';

import './styles/post.scss';
import Layout from '../layouts/blog';
import PostGlobals from './template-const/PostGlobals';

const PostTemplate = ({ pageContext }) =>  {
  const element = useRef(null);
  const isVisible = useOnScreen(element);
  const filteredCategory = /(Newsletter|Articles|Contest Terms|Inline Cta)/;
  const filteredCategoryList = pageContext.categories.filter(category => !filteredCategory.test(category.name));
  const schemaType = pageContext.yoast_head_json.schema.wordpress__graph[0].wordpress__type ?? 'BlogPosting';
  const schemaHeadline = pageContext.yoast_head_json.schema.wordpress__graph[0].headline;
  const schemaImage = pageContext.yoast_head_json.og_image && pageContext.yoast_head_json.og_image[0].url && pageContext.yoast_head_json.og_image[0].url.source_url;
  const schemaDatePublished = pageContext.yoast_head_json.schema.wordpress__graph[0].datePublished;
  const schemaDateModified = pageContext.yoast_head_json.schema.wordpress__graph[0].dateModified;
  const schemaAuthorName = pageContext.yoast_head_json.schema.wordpress__graph[0].author.name ?? 'Sam Campbell';
  const signupContent = pageContext.acf?.persistent_post_signup;

  const postRS = `{
    "@context": "https://schema.org",
    "@type": "${schemaType}",
    "headline": "${schemaHeadline}",
    "image": ["${schemaImage}"],
    "datePublished": "${schemaDatePublished}",
    "dateModified": "${schemaDateModified}",
    "author": [{
        "@type": "Person",
        "name": "${schemaAuthorName}"
      }]
  }`;

  const renderSignup = () => {
    return <div className="blog-signup-cta">
      <h4>{signupContent?.cta_heading || 'Try When I Work for free'}</h4>
      <Signups
        uniqueId="blogPost"
        longPlaceholder
        fieldLayout="row"
        buttonClasses="button is-signup-button is-rounded is-primary"
        showModalButton
        ctaVariation='Blog post right column'
        signupModalLaunch={signupContent?.cta_text || 'Get started'}
      />
      <img
        src={signupContent?.cta_image?.url?.source_url || 'https://marketing-assets.wheniwork-production.com/2025/03/19101553/blog-cta-shifts.svg'}
        alt="Shifts"
        role="presentation"
      />
    </div>;
  }

  return (
    <Layout activeCategory={ filteredCategoryList[0]?.slug } hideCategoryDescription>
      <Yoast
        { ...pageContext.yoast_head_json }
        canonical={ `https://${process.env.GATSBY_SITE_URL}/blog/${pageContext.slug}` }
      />
      <Helmet>
        <script type="application/ld+json">
          {postRS}
        </script>
      </Helmet>

      <div className="post-template-container" data-signal={ pageContext.wordpress_id }>
        <img ref={ element }  className="post-hero-image" src={ pageContext?.header_image?.source_url ?? 'https://marketing-assets.wheniwork-production.com/2021/12/11223232/generic_blog_image_post_header.jpg' } role="presentation" />
        <article className="post" data-post={ pageContext.wordpress_id }>
          <h1
            className="post-title"
            dangerouslySetInnerHTML={ { __html: pageContext.title } }
          />
          <section className="entry-meta">
            <img
              className="avatar"
              src={ pageContext.author.avatar_urls.wordpress_96 }
              alt="Persons Avatar"
            />
            by
            <div className="entry-author">
              <span
                dangerouslySetInnerHTML={ { __html: pageContext.author.name } }
              />
            </div>
          </section>
          <section data-section="mainPostEntrySection" className="post-entry">
            <div className="right-column-signup">{renderSignup()}</div>
            <PostContent content={ pageContext.content } />
          </section>
          {pageContext.categories[0].name === 'uncategorized' ? (
            null
          ) : (
            <footer
              className="entry-footer"
              data-section="blogPostFooterCategoriesLinks"
            >
              {filteredCategoryList.length > 0 &&
                <div className="article-categories">
                  <h3>section:</h3>
                  <ul className="post-categories">
                    {filteredCategoryList.map((post_category, index) => (
                      <li key={ index }>
                        <Link
                          to={ post_category.slug }
                          dangerouslySetInnerHTML={ { __html: post_category.name } }
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              }

              <div className="article-date">
                <FontIcon icon="calendar" />
                <time
                  className="entry-date"
                  dangerouslySetInnerHTML={ { __html: format(new Date(pageContext.date), "MMM dd, yyyy") } }
                  title={pageContext.date}
                />
              </div>
            </footer>
          )}
        </article>
      </div>
      <PostGlobals />
      <ScrollToTop className={ `${!isVisible ? 'is-viewable' : ''}` } />
      <div className="post-fixed-signup">{renderSignup()}</div>
    </Layout>
  );
};
 

export default PostTemplate;
